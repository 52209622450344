<template>
  <div class="container oci-check-in-out-body">
    <b-card>
        <b-row class="pb-1">
            <b>&nbsp;&nbsp;Profile</b>
        </b-row>   
        <b-form>
            <b-row class="pb-1">
                <b-form-group
                    label="Name"
                    label-for="name-edit"
                    class="text-left w-100"
                >
                    <b-form-input
                    v-model="userName"
                    placeholder=""
                    name="name-edit"
                    disabled
                    />
                </b-form-group>
                </b-row>
                <b-row class="pb-1">
                <b-form-group
                    label="Email"
                    label-for="email-edit"
                    class="text-left w-100"
                >
                    <b-form-input
                    v-model="userEmail"
                    placeholder=""
                    name="email-edit"
                    disabled
                    />
                </b-form-group>
            </b-row>   
        </b-form>
    </b-card>  
    <b-card>
        <b-row class="pb-1">
            <b>&nbsp;&nbsp;Change password</b>
        </b-row>   
<validation-observer
                ref="resetPasswordForm"
                #default="{invalid}"
              >
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="resetPassword"
                >
                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">New Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="newPw"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="new-password"
                          placeholder="New Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />        
                          </b-input-group-append>                
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Confirm Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirm"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="confirmPw"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="confirm-password"
                          placeholder="Confirm Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />        
                          </b-input-group-append>                        
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    Save
                  </b-button>
                </b-form>
              </validation-observer>
    </b-card>     
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, password,
} from '@validations'
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import store from '@/store'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BImg,
    BCard, 
    BRow, 
    BCol, 
    BForm, 
    BFormGroup, 
    BInputGroupAppend,
    BFormInput,
    BButton,
    BInputGroup,
    ValidationObserver, 
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ userEmail: 'app/getUserEmail' }),    
        
    subHeading() {
      return 'You have not shown in this project:'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },    
  },
  data() {
    return {
      newPw: '',
      confirmPw: '',        
      jsonData: [],
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    return
  },
  methods: {
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },   
    logOut(){
      console.log('logOut')
      this.$store.dispatch('app/logoutAndReset')
    },
    async resetPassword() {
      await loginMethods.SaveUser(this.newPw, this.logOut)
        .then(result => {
          // console.log(result)
          // this.loginDisabled = false
          this.$store.dispatch('app/logoutAndReset')
        })
        .catch(error => {
          // console.log(error)
          //this.loginDisabled = false
        })
    },        
  },
}
</script>
